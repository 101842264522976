<template>
  <div id="editor">
    <vue-editor ref="editor" v-model="strHtml" :disabled='!!disabled'></vue-editor>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "Editor",
  props: ["content", "disabled"],
  data() {
    return {
      strHtml: ""
    };
  },
  components: { VueEditor },
  watch: {
    $attrs(val) {
      this.strHtml = val.value;
    },
    strHtml(newval) {
      this.$emit("input", newval);
    }
  },
  methods: {
  }
};
</script>