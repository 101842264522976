<template>
  <div class="articleedit">
    <div class="CommonWidth">

      <div class="row">
        <el-row class="common rwoh"
          ><editor v-model="content" :content="content" class="editor"
        /></el-row>
      </div>

      <div class="row">
          以下为文章内容，请复制到变量文件内, 需要添加远程图片用 <pre class="ql-syntax" spellcheck="false">&lt;img src="图片文件地址"&gt;
</pre>
        <el-input type="textarea" placeholder="请输入内容" v-model="content">
        </el-input>
      </div>

    </div>
  </div>
</template>

<script>
import editor from "@/components/Editor.vue";
export default {
  name: "Article",
  data() {
    return {
      content: null,
    };
  },
  methods: {},
  components: {
    editor,
  },
};
</script>

<style lang="scss" scoped>
    .articleedit{
        .row{
            margin-bottom: 100px;
        }
    }
</style>